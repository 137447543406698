var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-body-2",style:({
        textAlign: 'start',
        color: _vm.color || _vm.$vuetify.theme.themes.light.primary
      })},'span',attrs,false),on),[_vm._v(" "+_vm._s(!_vm.hideLabel ? _vm.$t('howToPlay') : '')+" "),_c('v-tooltip',{attrs:{"top":_vm.$vuetify.breakpoint.smAndUp,"left":!_vm.$vuetify.breakpoint.smAndUp,"content-class":"bottom-arrow"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.color || 'primary',"size":_vm.iconSize || 'small'}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('span',[_vm._v("Instructions available here")])])],1)]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary white--text"},[_vm._v(" "+_vm._s(_vm.$t('howToPlay'))+" ")]),_c('v-card-text',{staticClass:"mt-2"},[_c('p',{staticClass:"text-body-2",style:({
          textAlign: 'start',
          color: _vm.$vuetify.theme.themes.light.primary
        }),domProps:{"innerHTML":_vm._s(_vm.$t(_vm.i18n_instructions_key))}})]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" "+_vm._s(_vm.$t('OK'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }