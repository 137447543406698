<template>
  <div class="justify-center">

      <Matcher :i18n_instructionMsg_key="'matchJointWordsInstruction'" :matchList="match" :numberOfMatches="match.length"/>

  </div>
</template>

<script>
import Matcher from './Matcher'

export default {
  components: {
    Matcher
  },

  methods: {

  },
  data: () => ({

    match: [
      {
        eng: 'ગ્+ર્+અ+હ્+અ+ણ્+અ',
        guj: 'ગ્રહણ',
        hindi: 'ગ્+ર્+અ+હ્+અ+ણ્+અ'
      },
      {
        eng: 'દ્+ર્+અ+શ્+ય્+અ',
        guj: 'દ્રશ્ય',
        hindi: 'દ્+ર્+અ+શ્+ય્+અ'
      },
      {
        eng: 'ત્+ર્+ઈ+મ્+અં+ત્+ર્+અ',
        guj: 'ત્રિમંત્ર',
        hindi: 'ત્+ર્+ઈ+મ્+અં+ત્+ર્+અ'
      },
      {
        eng: 'દ્+આ+દ્+આ+શ્+ર્+ઈ',
        guj: 'દાદાશ્રી',
        hindi: 'દ્+આ+દ્+આ+શ્+ર્+ઈ'
      },
      {
        eng: 'વ્+ઋ+ત્+ત્+ઇ',
        guj: 'વૃત્તિ',
        hindi: 'વ્+ઋ+ત્+ત્+ઇ'
      },
      {
        eng: 'મ્+ઋ+ગ્+અ',
        guj: 'મૃગ',
        hindi: 'મ્+ઋ+ગ્+અ'
      },
      {
        eng: 'પ્+ર્+એ+મ્+અ',
        guj: 'પ્રેમ',
        hindi: 'પ્+ર્+એ+મ્+અ'
      },
      {
        eng: 'પ્+ર્+અ+ત્+આ+પ્+ઈ',
        guj: 'પ્રતાપી',
        hindi: 'પ્+ર્+અ+ત્+આ+પ્+ઈ'
      },
      {
        eng: 'ત્+એ+જ્+અ+સ્+વ્+ઈ',
        guj: 'તેજસ્વી',
        hindi: 'ત્+એ+જ્+અ+સ્+વ્+ઈ'
      },
      {
        eng: 'પ્+ઋ+થ્+વ્+ઈ',
        guj: 'પૃથ્વી',
        hindi: 'પ્+ઋ+થ્+વ્+ઈ'
      }
    ]

  })
}
</script>
<style>

</style>
